<template>
  <div class="goods-contrast">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item>商品对比</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>商品对比</h3>
      <div v-if="contrastList.length == 0">您还没选择商品进行对比</div>
      <table v-if="contrastList.length > 0" class="table-style">
        <caption>商品详细</caption>
        <tr>
          <td>商品</td>
          <td class="shop-name" v-for="(item,index) in contrastList" :key="index">{{item.name}}</td>
        </tr>
        <tr>
          <td>图片</td>
          <td v-for="(item,index) in contrastList" :key="index">
            <img style="width:100px;height:100px" :src="item.img" alt="">
          </td>
        </tr>
        <tr>
          <td>价格</td>
           <td class="shop-name" v-for="(item,index) in contrastList" :key="index">￥{{item.price}}</td>
        </tr>
        <!-- <tr>
          <td>型号</td>
          <td>aaa</td>
        </tr> -->
        <!-- <tr>
          <td>品牌</td>
          <td>aaa</td>
        </tr> -->
        <tr>
          <td>库存状态</td>
           <td  v-for="(item,index) in contrastList" :key="index">{{item.stock}}</td>
        </tr>
        <tr>
          <td>评分</td>
          <td v-for="(item,index) in contrastList" :key="index">
            <el-rate disabled-void-color="#d2d2d2" :value="item.star > 5 ? 5 : item.star" disabled></el-rate>
            <div style="font-size: 14px;margin-top: 10px;"> 基于 {{item.star}} 评价。</div>
          </td>
        </tr>
        <!-- <tr>
          <td>概要</td>
          <td>aaa</td>
        </tr> -->
        <!-- <tr>
          <td>重量</td>
          <td>aaa</td>
        </tr>
        <tr>
          <td>尺寸（长 × 宽 × 高）</td>
          <td>aaa</td>
        </tr> -->
        <tr>
          <td></td>
          <td v-for="(item,index) in contrastList" :key="index">
            <div>
              <el-button class="add-car">加入购物车</el-button>
              <el-button @click="del">移除</el-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'goodsContrast',
  data() {
    return {
      contrastList:[]
    }
  },
  mounted() {
    this.contrastList = JSON.parse( localStorage.getItem('contrastList')) || []
    console.log(this.contrastList);
  },
  methods:{
    del(index) {
      this.contrastList.splice(index,1);
      localStorage.setItem('contrastList',JSON.stringify(this.contrastList))
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
:deep(.add-car) {
    color: #000;
    background-color: #ffda00;
    border-color: #ffda00;
}
.goods-contrast {
  @include body;
  .table-style {
    border: 1px solid #dddddd;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    caption {
      padding: 5px 0;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #dddddd;
    }
    tr {
      td {
        padding: 5px 0;
        border: 1px solid #dddddd;
      }
      td:nth-child(1) {
        width: 5%;
      }
      td:nth-child(2) {
        width: 20%
      }
       td:nth-child(3) {
        width: 20%
      }
       td:nth-child(4) {
        width: 20%
      }
       td:nth-child(5) {
        width: 20%
      }
    }
    tr:last-child {
      td {
        div {
          display: flex;
          padding: 0 10px;
          .el-button {
            width: 50%;
          }
        }
      }
    }

    .shop-name {
      font-size: 14px;
      color: #ffda00;
      font-weight: bold;
    }
  }
}
</style>
